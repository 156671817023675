@mixin generate-spaces-xs($num) {
    @for $i from 0 through $num {
        $value: multiply-unit($i, $spacingMultiplier);
        .u-my-xs-#{$i} { margin-top: $value; margin-bottom: $value; }
        .u-mt-xs-#{$i} { margin-top: $value; }
        .u-mb-xs-#{$i} { margin-bottom: $value; }
        .u-py-xs-#{$i} { padding-top: $value; padding-bottom: $value; }
        .u-pt-xs-#{$i} { padding-top: $value }
        .u-pb-xs-#{$i} { padding-bottom: $value }
    }
}

@mixin generate-spaces-sm($num) {
    @for $i from 0 through $num {
        $value: multiply-unit($i, $spacingMultiplier);
        .u-my-sm-#{$i} { margin-top: $value; margin-bottom: $value; }
        .u-mt-sm-#{$i} { margin-top: $value; }
        .u-mb-sm-#{$i} { margin-bottom: $value; }
        .u-py-sm-#{$i} { padding-top: $value; padding-bottom: $value; }
        .u-pt-sm-#{$i} { padding-top: $value; }
        .u-pb-sm-#{$i} { padding-bottom: $value; }
    }
}

@mixin generate-spaces-md($num) {
    @for $i from 0 through $num {
        $value: multiply-unit($i, $spacingMultiplier);
        .u-my-md-#{$i} { margin-top: $value; margin-bottom: $value; }
        .u-mt-md-#{$i} { margin-top: $value; }
        .u-mb-md-#{$i} { margin-bottom: $value; }
        .u-py-md-#{$i} { padding-top: $value; padding-bottom: $value; }
        .u-pt-md-#{$i} { padding-top: $value; }
        .u-pb-md-#{$i} { padding-bottom: $value; }
    }
}

@mixin generate-spaces-lg($num) {
    @for $i from 0 through $num {
        $value: multiply-unit($i, $spacingMultiplier);
        .u-my-lg-#{$i} { margin-top: $value; margin-bottom: $value; }
        .u-mt-lg-#{$i} { margin-top: $value; }
        .u-mb-lg-#{$i} { margin-bottom: $value; }
        .u-py-lg-#{$i} { padding-top: $value; padding-bottom: $value; }
        .u-pt-lg-#{$i} { padding-top: $value; }
        .u-pb-lg-#{$i} { padding-bottom: $value; }
    }
}

@function multiply-unit($value1, $value2) {
    @return ($value1 * $value2)+$spacingUnit;
  }

@include generate-spaces-xs(15);
@media(min-width: $small) {
    @include generate-spaces-sm(15);
}
@media(min-width: $medium) {
    @include generate-spaces-md(15);
}
@media(min-width: $large) {
    @include generate-spaces-lg(15);
}