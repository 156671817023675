input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #aaa;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #aaa;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #aaa;
}
:-moz-placeholder { /* Firefox 18- */
  color: #aaa;
}
button,
datalist,
input,
select,
textarea,
legend,
label,
.label {
	line-height: normal;
}
button,
datalist,
input,
select,
textarea {
	border-radius: 0;
	border: 0;
	padding: 1em 1.25em;
	text-transform: none;
	&:focus {
		outline: none;
	}
}
input,
select,
textarea {
	border: 1px solid #ddd;
	&:focus {
		border-color: #bbb;
	}
}
textarea {
	min-height: 8em;
}
legend {
	font-weight: bold;
	font-size: 1.25rem;
}
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
select::-ms-expand {
	display: none;
}
.c-form__content {
	display: block;
}
.c-form__entry {
	margin-bottom: $paragraph-margin;
	margin-top: $paragraph-margin;
}
.c-form__label {
	display: block;
	margin-bottom: 0.25em;
}
.c-form__field {
	display: block;
}
.c-form__input {
	width: 100%;
	&--s {
		max-width: 6em;
	}
	&--m {
		max-width: 12em;
	}
}
.c-radio,
.c-checkbox {
	display: flex;
	cursor: pointer;
}
.c-radio__input,
.c-checkbox__input {
	width: 1em;
	margin-right: 0.5em;
	position: relative;
	.c-form__input {
		opacity: 1; // set to zero for custom
	}
}
.c-radio__custom,
.c-checkbox__custom {
	display: none; // remove for custom
	background-color: transparent;
	border: 2px solid #ccc;
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 1em;
	height: 1em;
	&:before {
		content: '';
		background-color: #ccc;
		border-radius: 50%;
		position: absolute;
		right: 50%;
		top: 50%;
		transform: translateY(-50%) translateX(50%);
		width: 0.5em;
		height: 0.5em;
		opacity: 0;
	}
}
.c-checkbox__custom {
	&, &:before {
		border-radius: 0;
	}
}
.c-radio input[type="radio"]:checked + .c-radio__custom:before,
.c-checkbox input[type="checkbox"]:checked + .c-checkbox__custom:before {
	opacity: 1;
}
.c-select {
	&__field {
		position: relative;
	}
	&__icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 1.25rem;
	}
	select {
		background-color: transparent;
		position: relative;
		height: 3.25rem;
		width: 100%;
		z-index: 1;
	}
	@media(min-width: 40em) {
		&__field {
			margin: -0.75em 0;
		}
	}
}
.c-form__msg {
	color: #555;
	display: block;
	margin-bottom: 0.5em;
	margin-top: 0.5em;
	background-color: #eee;
	padding: 0.75rem 1.25rem;
	font-size: 0.875rem;
}

@media(min-width: 40em) {
	.c-form__entry {
		margin: 0.5em 0;
	}
	.c-form__content {
		padding: .75em 0;
		display: flex;
		@supports (display: grid) {
			display: grid;
			grid-template-columns: 1fr 2fr;
		}
	}
	.c-checkbox,
	.c-radio {
		display: flex;
		@supports (display: grid) {
			display: grid;
		    grid-template-columns: 1.3rem 2fr;
		}
	}
	.c-form__push {
		display: block;
		@supports (display: grid) {
			display: grid;
		    grid-template-columns: 1fr 2fr;
		    > * {
		    	grid-column-start: 2;
		    }
		}
	}
	.c-form__input {
		margin: -0.75em 0;
	}
	.c-form__label {
		line-height: normal;
		margin: 0;
		padding: 0 2em 0 0;
		flex: 1;
	}
	.c-form__field {
		flex: 2;
	}
	.c-radio,
	.c-checkbox {
		padding: 0.15em 0;
	}
	.c-radio__input,
	.c-checkbox__input {
		margin-right: 0.3em;
		padding: 0;
	}
	.c-form__columns {
		columns: 2;
	}
}
.c-form__error {
	input,
	select,
	textarea {
		border: 1px solid red;
	}
	.c-radio__custom,
	.c-checkbox__custom {
		border-color: red;
	}
	.c-form__msg {
		padding: 0;
		background-color: #fff;
		color: red;
	}
}