/*
  Objects are immutable once they are set.
  Please extend them with modifiers,
  or use a component class to make exceptions.
*/

.o-list {
  padding: 0;
  list-style: none;
}

.o-block {
  display: flex;
  align-items: flex-start;
  margin-left: -$block-margin;
  margin-right: -$block-margin;
  &--v-center {
    align-items: center;
  }
}
.o-block__media {
  margin: 0 $block-margin;
 
}
.o-block__body {
  flex: 1;
  margin: 0 $block-margin;
}

.o-media {
  background-color: #000;
  display: block;
  padding: 0 0 56.25%;
  position: relative;
  img {
    position: absolute;
  }
  iframe,
  object,
  embed {
    height: 100% !important;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100% !important;
  }
}
