.c-box {
	height: 100%;
	padding: 1.5em;
}
.c-box__title {
	background-color: rgba(0,0,0, 0.1);
	display: table;
	font-size: 16px;
	letter-spacing: 1px;
	padding: 0.75em 1em;
	border-radius: 4px;
	text-align: center;
	width: auto;
	margin: 0 auto 2em;
}
