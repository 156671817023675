.pagination {
    margin-top: 3rem;
    margin-bottom: 3rem;
    a, span {
        background: #F3F3F5;
        border-radius: 2px;
        font-weight: 700;
        color: $text-color;
        display: block;
        padding: 0.7em 1em;
        font-size: 14px;
        text-decoration: none;
    }
    a:not(.active) {
        &:hover, &:focus, &:active {
            background-color: darken(#F3F3F5, 5%);
        }
    }
    a.active {
        background: #bfcccf;
        color: #fff;
    }
    ul {
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0.25em;
        list-style: none;
    }
    li {
        margin: 0.25em;
        &:first-child {
            span { display: none; }
        }
    }

}
