.c-banner {
    margin-top: 1.5rem;
    &__inner {
        padding: 1rem;
        background-color: #eee;
        @media (min-width: 640px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        @media (min-width: 768px) {
            padding-left: 2.2rem;
            padding-right: 2.2rem;
        }
    }
    &__text {
        font-size: 18px;
        font-weight: 700;
        @media (min-width: 640px) {
            font-size: 24px;
        }
    }
    &__button {
        margin: 0;
        margin-top: 0.5rem;
    }
}