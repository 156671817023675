.c-menu__wrapper {
    position: absolute;
    left: 50%;
    top: 39px;
    transform: translateX(-50%);
    width: 280px;
    background-color: #EBEFF1;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.24);

    pointer-events: none;
    opacity: 0;
    transition: 0.2s;

    border-radius: 4px;

    &:before {
        @media(min-width: 800px) {
            content: '';
            background-image: url('/assets/images/ic-nav-connector.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center bottom;
            position: absolute;
            top: 2px;
            width: 35px;
            height: 35px;
            left: 50%;
            transform: translateX(-50%) translateY(-100%);
        }
    }

    &.is-active {
        pointer-events: all;
        opacity: 1;
    }

    .c-header__meta {
        padding: 10px 20px;
        @media(min-width: 800px) {
            display: none;
        }
    }

    .c-header__meta-item--social {
        display: block;
        @media(min-width: 800px) {
            display: none;
        }
    }

    @media(min-width: 800px) {
        top: 75px;
    }
}

.c-menu__list {
    margin: 0;
    
    li {
        border-bottom: 1px solid #e8e8e8;
        margin: 0;
        &:last-of-type {
            border-bottom: 0;
        }
        ul {
            display: none;
            li {
                border-bottom: 0;
            }
        }
        a {
            text-decoration: none;
            display: block;
            padding: 16px 37px 16px 46px;
            color: #000;
            transition: 0.16s;
            position: relative;
            opacity: 0.6;
            &:before {
                content: '';
                background-image: url('/assets/images/ic-nav-arrow.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center bottom;
                position: absolute;
                top: 50%;
                width: 0.9em;
                height: 0.9em;
                left: 16px;
                transform: translateY(-50%);
                transition: 0.16s;
                opacity: 0.2;
            }
            &:hover {
                background-color: transparent;
                opacity: 1;
                &:before {
                    opacity: 1;
                    transform: translateY(-50%) translateX(7px);
                }
            }
            &.is-active {
                opacity: 1;
                &:before {
                    opacity: 1;
                    transform: translateY(-50%);
                }
            }

        }
    }
}

.c-menu__trigger {
    background-image: url('/assets/images/menu.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $orange;
    border-radius: 4px;
    letter-spacing: 1px;
    text-decoration: none !important;
    display: block;
    position: absolute;
    right: 46px;
    bottom: 0;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0;
    width: 2.5rem;
    height: 2.5rem;
    &:visited {
        color: #fff;
    }
    &:hover, &:focus, &:active {
        background-color: darken($orange, 5%);
        text-decoration: none;
        color: #fff;
        &:after {
            border-color: darken($orange, 5%);
        }
    }
    @media(min-width: 800px) {
        display: flex;
        align-items: center;
        font-size: 13px;
        padding: 0.75rem 1rem;
        padding-left: 3rem;
        left: 50%;
        right: auto;
        bottom: 7px;
        transform: translateX(-50%);
        width: auto;
        background-position: 1rem center;
    }
}

.c-language__list {
    position: absolute;
    background-color: #EBEFF1;
    width: 320px;
    margin-top: 0;
    top: 39px;
    right: 0;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    @media(min-width: 800px) {
        top: 65px;
    }
    li {
        border-bottom: 1px solid #e8e8e8;
        margin: 0;
        &:last-of-type {
            border-bottom: 0;
        }
        ul {
            display: none;
            li {
                border-bottom: 0;
            }
        }
        a {
            text-decoration: none;
            display: block;
            padding: 13px 20px;
            color: #000;
            font-weight: bold;
            transition: 0.12s;
            &:hover {
                background-color: transparent;
                padding-left: 27px;
            }

        }
    }

    pointer-events: none;
    opacity: 0;
    transition: 0.2s;

    &.is-active {
        pointer-events: all;
        opacity: 1;
    }
}

.c-header__meta {
    margin: 0;
    display: flex;
}

.c-header__meta-item--social {
    display: none;
    @media(min-width: 800px) {
        display: block;
    }
}

.c-header__meta-item {
    margin: 0;
    width: 2.5rem;
    height: 2.5rem;
    &+.c-header__meta-item {
        margin-left: 8px;
    }
}

.c-header__meta-link {
    background-color: #bfcccf;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 17px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    height: 100%;
    width: 100%;
    transition: 0.1s;
    &--social {
        img { max-width: 1.3rem; }
    }
    &:hover {
        background-color: $orange;
    }
    &:visited, &:hover {
        color: #fff;
    }
}

.c-header__logo {
    width: 160px;
    @media(min-width: 800px) {
        width: auto;
    }
}
