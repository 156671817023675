$text-color: #2e4050;
$primary-color: #ff6c00;
$orange: #ff6c00;
$orange-light: #ff8e21;
$green: #44a140;
$yellow: #f7ae00;
$blue: #4099ff;
$red: #d8432d;
$lilac: #6569ce;
$paragraph-margin: 1.5rem;
$nav-change: 50em;
//media object margins
$block-margin: 0.5rem;
$spacing: 0.75rem;


//Breakpoints for marge/padding utilities
$small: 48em;
$medium: 62em;
$large: 75em;
$spacingUnit: em; //Can be any unit. px/em/rem/vw/vmax
$spacingMultiplier: 1; //Example: set to 10 if using PX as unit. This will result in 10px/20px/30px/etc.
