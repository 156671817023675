.c-figure {
    margin: 0;
    position: relative;
    img {
        margin: 0;
        width: 100%;
    }
    figcaption {
        position: absolute;
        right: 0;
        top: 100%;
        font-size: 11px;
        margin: 0;
        padding: 3px 6px;
    }
}
