.accessibility,
.js .js-accessibility {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute;
	width: 1px;
}
.accessibility:focus,
.js .js-accessibility:focus {
	clip: auto;
	height: auto;
	overflow: visible;
	position: fixed;
	width: auto;
}
.js .js-hide,
.js .js-remove {
	display: none;
}
