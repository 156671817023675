.c-header {
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    a:hover, a:active {
        text-decoration: none;
    }
}

.c-header__inner {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
}

.c-header__logo {
    bottom: 4px;
    position: relative;
}
