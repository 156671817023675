.c-error {
    margin-top: 5rem;
    @media only screen and (min-width: 800px) {
        margin-top: 10rem;
    }
}

.c-error-btn, .c-error-btn:visited {
    background-color: $orange;
    color: #fff;
    text-decoration: none;
    border-radius: 3px;
    padding: 0.5em 1em;
    font-size: 22px;
    &:hover {
        text-decoration: none;
        background-color: lighten($orange, 5%);
    }
}