$button-margin: 0.35em;

.c-btn, button {
	display: inline-block;
	vertical-align: middle;
	font-family: inherit;
	font-weight: bold;
	padding: 1em 1.25em;
	border: 1px solid transparent;
	transition: background-color 0.15s ease-out, color 0.15s ease-out;
	line-height: normal;
	margin: $button-margin 0;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	background-color: #ededed;
	-webkit-appearance: none;
	border-radius: 4px;
	letter-spacing: 1px;

	&:hover, &:focus, &:active {
		background-color: darken(#ededed, 10%);
		color: inherit;
		outline: none;
		text-decoration: none;
	}
	&, &:visited {
		color: inherit;
	}
}
.c-btn--primary {
	background-color: $primary-color;
	&:hover, &:focus, &:active {
		background-color: darken($primary-color, 10%);
		color: #fff;
	}
	&, &:visited {
		color: #fff;
	}
}
.c-btn--dark {
	background-color: $text-color;
	&:hover, &:focus, &:active {
		background-color: darken($text-color, 10%);
		color: #fff;
	}
	&, &:visited {
		color: #fff;
	}
}
.c-btn-group {
	margin: -$button-margin;
	.c-btn, button{
		margin: $button-margin;
	}
}
