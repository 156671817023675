.c-cols {
    list-style: none;
    padding: 0;
    margin: -1em;

    .c-cols__item {
        padding: 1em;
        margin: 0;
    }

    @media(min-width: 800px) {
        display: flex;
        flex-wrap: wrap;
        .c-cols__item {
            width: 33.333%;
        }
    }
}
