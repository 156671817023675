.c-res {
   
    padding-bottom: 0;
    margin-bottom: -3rem;
    &__article {
        margin-bottom: 42px;
    }
    &__header { margin-bottom: 30px; }
    &__heading { padding: 0; }
    &__section {
        padding-top: 40px;
        padding-bottom: 160px;
        @media (min-width: 768px) {
            margin-left: -$paragraph-margin*2;
            margin-right: -$paragraph-margin*2;
        }
    }
    &__content {
        @media (min-width: 768px) {
            display: flex; 
        }
    }
    &__slots {
        background-color: #fff;
        padding-top: 1px;
        padding-bottom: 50px;
        @media (min-width: 768px) {
            // margin-left: -$paragraph-margin;
            // margin-right: -$paragraph-margin;
        }
    }
    &__form {
        margin: 0 0 1em;
    }
    &__title {
        line-height:1.2;
    }
    &__legend {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 40px;
        text-align: center;
        &--first {
            // margin-bottom: 0.5em;
            margin-top: 40px;
        }
        @media (min-width: 768px) {
            font-size: 30px;
        }
    }
    &__row {
        margin: 1em 0;
        .c-res__label {
            display: block;
            margin-bottom: 0.5em;
            padding-right: 1em;
        }
        @media (min-width: 640px) {
            display: flex;
            align-items: center;
            .c-res__label {
                flex: 0 0 40%;
                margin: 0;
                text-align: right;
                max-width: 280px;
            }
        }
        
    }
    &__input {
        width: 100%;
        max-width: 360px;
        border-radius: 4px;
        &[type="tel"] {
            max-width: 240px;
        }
    }
    &__row--select {
        margin-bottom: 2em;
    }
    &__select {
        select {
            background-image: url('/assets/images/ic-chevron-down.svg');
            background-position: calc(100% - 1rem) center;
            background-repeat: no-repeat;
            padding-right: 2.5rem;
            border-radius: 4px;
        }
    }
    &__row--checkbox {
        input { margin-right: 6px; }
        label { font-size: 14px; }
    }
    &__row--submit {
        margin-top: 2em;
        button[type="submit"] {
            border-radius: 4px;
            background-color: $text-color;
            color: #fff;
            &:hover {
                background-color: darken($text-color, 10%);
            }
        }
    }
    &__message {
        font-size: 12px;
        font-weight: 400;
    }
    &__res {
        display: flex;
        align-items: center;
        color: $orange;
        background-color: #fff;
        border-radius: 4px;
        padding: 1em 1.5em;
        * {margin: 0; line-height: 1; }
    }
}
.uniform__potty { display: none; }
.uniform-erros__item, .error-text {
    display: block;
    color: red;
    margin-left: 1.3em;
    margin-top: 0.5em;
    margin-bottom: 2em;
}
.c-thanks {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: $paragraph-margin;
    padding-right: $paragraph-margin;
    background-color: #eee;
    margin-top: -40px;
    @media (min-width: 768px) {
      padding-left: $paragraph-margin*2;
      padding-right: $paragraph-margin*2;
    }
}
.c-thanks__message {
  margin-top: 30px;
    @media (min-width: 768px) {
      display: flex;
    }
}
.c-thanks__overview {
    background-color: #fbfbfb;
    border-radius:4px;
    padding: 1em;
    width: 100%;
    margin-bottom: 30px;
    @media (min-width: 768px) {
      max-width: 320px;
      margin-left: 30px;
      order: 2;
    }
}
.c-thanks__text {
  order: 1;
}
.additional-info {
    &__inner {
        background-color: #eee;
        padding: 1em;
        @media (min-width: 768px) {
            padding: 2.5em;
        }
    }
    @media (min-width: 768px) {
        padding: 1.5em;
        padding-right: 0;
        max-width: 480px;
    }
}
.additional-info-pictos {
    display: flex;
    margin: 0 -0.25em;
    img {
      width: 50%;
      max-width: 150px;
      border: 1px solid #eee;
      margin: 0 0.25em;
    }
}
// Timeslot Calendar
.tsc-calendar {
  overflow: hidden;
  border-radius: 4px;
}
.tsc-calendar,
.tsc-month {
  background-color: #eee !important;
}
.tsc-month__action {
  font-family: inherit !important;
}
.tsc-month__actions {
  margin: 0;
  font-family: inherit !important;
  color: #ff6c00;
  align-items: center;
}
.tsc-month__action-element {
  font-size: 1.1em !important;
  cursor: pointer;
  width: 1.7em;
  height: 1.7em;
  background-color: #2e4050;
  border-radius: 50%;
  color: #fff !important;
  display: block !important;
  padding: 0 !important;
}
.tsc-month__action-element,
.tsc-calendar__action-element {
    transition: 0.3s;
}
.tsc-month__action-element:hover {
  transform: scale(1.1);
}
.tsc-calendar__action-element:hover {
  opacity: 0.6;
}
.tsc-day {
  margin-bottom: 2em;
}
.tsc-day__title {
  font-weight: normal;
  font-family: inherit !important;
  padding: 0.4em !important;
  text-transform: none !important;
  font-size: 0.9em;
  color: #ff6c00;
  white-space: nowrap;
}
.tsc-calendar__actions {
  /* display: none !important; */
  width: 100%;
  max-width: 250px;
  margin-left: auto;
  border: none;
  border-bottom: none !important;
  border-radius: 0;
  background-color: #eee;
  font-family: inherit !important;
  margin: auto;
}
.tsc-calendar__action {
  font-weight: 400;
  font-family: inherit !important;
}
.tsc-calendar__action-title {
  font-size: 1em;
  text-transform: none !important;
  padding: 0.4em 0 !important;
  font-family: inherit !important;
  margin-top: 2px;
}
.tsc-calendar__action-element {
  font-family: inherit !important;
}
.tsc-week {
  margin: 0;
}
.tsc-timeslot {
  margin: 0.4em !important;
  padding: 0.6em !important;
  font-family: inherit !important;
}
.uniform__potty {
  position: absolute;
  left: -9999px;
}
#bookingDate,
#bookingTime {
  display: none;
}
@media only screen and (min-width: 768px) {
  .tsc-calendar {
    flex-direction: column-reverse !important;
    padding: 0.8em 1.5em !important;
  }
  .tsc-month__actions {
    margin-bottom: 10px;
    margin-left: 6px;
    margin-right: 6px;
    position: relative;
  }
  .tsc-calendar__actions {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: -16px;
  }
  .tsc-day {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
    .tsc-timeslot--disabled {
        display: none !important;
    }
}