.c-cta {
    background-color: $primary-color;
    display: block;
    margin-top: 24px;
    position: relative;
    text-decoration: none;
    background-size: cover;
    background-position: center;

    &:visited, & {
        color: #fff;
    }
    &:before {
		content: "";
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
        padding-bottom: 70%;
    }

    .c-cta__content {
        opacity: 0;
        transition: 0.12s;
        h3 {
            line-height: 1.1;
        }
    }

    &:hover, &:focus, &:active {
        text-decoration: none;
        .c-cta__content {
            opacity: 1;
        }
        .c-cta__play {
            opacity: 0.8;
        }
    }
    @media(min-width: 800px) {
        &:before {
            padding-bottom: 134.1%;
        }
        &--small:before {
            padding-bottom: 62.434%;
        }
        &--medium:before {
            padding-bottom: 98.937%;
        }
    }
	&:after {
		content: "";
		display: table;
		clear: both;
	}
    &--subpages {
        &:before {
            padding-bottom: 73%;
        }
        .c-cta__label {
            padding-left: 10px;
        }
    }
}

.c-cta__label {
    display: table;
    background-color: $primary-color;
    margin: 0 auto;
    padding: 14px 10px;
    padding-left: 45px;
    border-radius: 3px;
    font-size: 13px;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    word-break: break-word;
    hyphens: auto;
    color: #fff;
    position: relative;
    top: 24px;
    pointer-events: none;
    z-index: 3;

    @media(min-width: 800px) {
        font-size: 14px;
    }

    .c-cta__label-image {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        &--small {
            width: 30px;
        }
        &--book {
            top: calc(50% + 2px);
        }
        &--collection {
            top: calc(50% + 1px);
        }
    }

    .c-cta--green & {
        background-color: $green;
    }
    .c-cta--orange & {
        background-color: $orange;
    }
    .c-cta--yellow & {
        background-color: $yellow;
    }
    .c-cta--lilac & {
        background-color: $lilac;
    }
    .c-cta--primary & {
        background-color: $primary-color;
    }
    .c-cta--transparent & {
        background-color: rgba(0,0,0, 0.1);
    }
    .c-cta--red & {
        background-color: $red;
    }
}

.c-cta__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba($primary-color, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-weight: bold;
    padding: 60px 20px 23px;

    @media(min-width: 1080px) {
        justify-content: center;
        padding: 60px 20px;
    }

    h3 {
        font-size: 24px;
        @media(min-width: 1080px) {
            font-size: 28px;
        }
        &:after {
            content: '';
            background-image: url('/assets/images/arrow-card.png');
            background-size: contain;
            width: 100%;
            padding-bottom: 4.61%;
            height: 0;
            display: block;
        }
    }

    p {
        display: none;
        @media(min-width: 1080px) {
            display: block;
        }
    }

    .c-cta--green & {
        background-color: rgba($green, 0.75);
    }
    .c-cta--orange & {
        background-color: rgba($orange, 0.75);
    }
    .c-cta--yellow & {
        background-color: rgba($yellow, 0.75);
    }
    .c-cta--lilac & {
        background-color: rgba($lilac, 0.75);
    }
    .c-cta--primary & {
        background-color: rgba($primary-color, 0.75);
    }
    .c-cta--red & {
        background-color: rgba($red, 0.75);
    }
}

.c-cta__play {
    width: 18%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.c-cta__iframe {
    overflow-y: scroll;
    height: 100%;
    position: absolute;
    padding: 85px 13px;
    top: 0;
    width: 100%;
}
