.u-stretch {
	min-height: 100vh;
}
.u-group {
	display: block;
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}
.u-aspect-box {
	padding: 30px;
	&:before {
		content: "";
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
		padding-bottom: calc(100% / (var(--aspect-ratio))); //Set aspect-ratio inline
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
[class*="u-content"] {
	margin-left: auto;
	margin-right: auto;
	max-width: 60rem;
}
.u-content {
	&-500 {
		max-width: 500px;
	}
	&-768 {
		max-width: 768px;
	}
	&-990 {
		max-width: 990px;
	}
}
.u-read {
	max-width: 34rem;
	&-20 {
		max-width: 20rem;
	}
	&-40 {
		max-width: 40rem;
	}
	&-50 {
		max-width: 50rem;
	}
}
.u-bg-quiet {
	background-color: #eee;
}
.u-bg-orange {
	background-color: $orange;
	&-darken {
		background-color: darken($orange, 5%);
	}
}
.u-bg-orange-light {
	background-color: $orange-light;
	&-darken {
		background-color: darken($orange-light, 5%);
	}
}
.u-bg-yellow {
	background-color: $yellow;
	&-darken {
		background-color: darken($yellow, 5%);
	}
}
.u-bg-green {
	background-color: $green;
	&-darken {
		background-color: darken($green, 5%);
	}
}
.u-bg-red {
	background-color: $red;
	&-darken {
		background-color: darken($red, 5%);
	}
}
.u-bg-lilac {
	background-color: $lilac;
	&-darken {
		background-color: darken($lilac, 5%);
	}
}
.u-bg-blue {
	background-color: $blue;
	&-darken {
		background-color: darken($blue, 5%);
	}
}
.u-contrast-alt {
	&:visited, & { color: #fff; }
}
.u-emphasized{
	font-size: 1.25rem;
}
.u-clean {
	text-decoration: none;
}
.u-reset {
	margin: 0;
}
.u-center {
	text-align: center;
}
.u-contain {
	position: relative;
}
.u-heading {
	text-transform: uppercase;
}
.u-weight-normal {
	font-weight: 400;
}
.u-font-28 {
	font-size: 22px;
}
@media(min-width: 800px) {
	.u-font-28 {
		font-size: 28px;
	}
}
.u-hide {
	display: none;
}
