.c-article {
    padding-bottom: 3rem;
    .c-article__header {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        text-align: center;
        margin-bottom: 1.5rem;

        @media(min-width: 800px) {
            margin-bottom: 3rem;
        }
    }
    .c-article__intro {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.6;
        text-align: center;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        @media(min-width: 800px) {
            margin-bottom: 3rem;
            margin-top: 3rem;
        }
    }
    .c-article__body p {
        max-width: 50rem;
    }
    @media(min-width: 800px) {
        .c-article__intro {
            font-size: 26px;
        }
    }
}
