#mediabank-demo {
    font-family: 'Lato', Arial, sans-serif;
}
.mediabank-detail-metadata {
	h1, .title {
		padding: 0;
		font-size: 16px;
	}
}
.mediabank-button-back,
#pic-mediabank-add-comment,
.mediabank-detail-permalink .mediabank-permalink-copy,
.mediabank-comment-form .mediabank-comment-button {
	color: #fff;
	background-color: $orange;
	&:hover, &:focus, &:active {
		background-color: darken($orange, 5%);
		color: #fff;
	}
}
.mediabank-gallery-mode .active a {
	color: $orange;
	&:hover, &:focus, &:active {
		background-color: darken($orange, 5%);
	}
}
.mediabank-detail-menu li.active a {
	color: $orange;
}
.mediabank-detail-panel-button {
	color: rgba(#222, 0.8);
	&:hover, &:focus, &:active {
		color: rgba(#222, 0.6);
	}
}
.mediabank-gallery-mode a {
	&:hover, &:focus, &:active {
		color: darken($orange, 5%);
	}
}
.mediabank-detail-panel-button i .pic-icon-comment-count {
	background-color: #fff;
	color: $orange;
}
.mediabank-search,
.mediabank-result-header {
	background-color: #eee;
	border-radius: 4px;
}
.mediabank-pagination .pagination-button-prev,
.mediabank-pagination .pagination-button-next {
	background-color: $orange;
	&:hover, &:focus, &:active {
		background-color: darken($orange, 5%);
	}
}
.mediabank-detail-menu {
	background-color: #d8d8d8;
}
.mediabank-result-header,
.mediabank-result-options,
.mediabank-result-pagination-top,
.mediabank-filters {
	display: none;
}
.mediabank-fluid.mediabank-gallery-horizontal {
	margin-top: 0;
	margin-left: -0.5em;
	margin-right: -0.5em;
}
.mediabank-search .mediabank-search-help {
	background-color: #fff;
	color: $text-color;
	&:hover, &:focus, &:active {
		background-color: darken(#fff, 5%);
	}
}
.mediabank-filter-list .mediabank-filter {
	&:hover, &:focus, &:active {
		color: #111;
	}
}
.mediabank-search .mediabank-search-submit,
.mediabank-filters-active .filter-active {
	background-color: $orange;
	border-radius: 0 4px 4px 0;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
	&:hover, &:focus, &:active {
		background-color: darken($orange, 5%);
	}
}
.mediabank-filters-active .filter-active {
	border-radius: 4px;
}
