.c-grid {
    list-style: none;
    @media(min-width: 800px) {
        display: flex;
        flex-wrap: wrap;
        margin: -12px -12px 0;
    }
}

.c-grid__item {
    @media(min-width: 800px) {
        margin: 0;
        padding: 12px;
        width: 33.3333%;
    }
}