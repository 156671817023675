.c-breadcrumbs {
    margin: 0 -1.5rem;
    padding: 1em $paragraph-margin;

    ul {
        display: flex;
        li {
            margin: 0 10px 0 0;
            a {
                font-size: 14px;
                font-weight: 400;
                color: #8a8a8a;
                display: flex;
                align-items: center;
                text-decoration: none;
                &:after {
                    content: "›";
                    margin-left: 10px;
                    text-decoration: none;
                }
                &:hover, &:focus, &:active {
                    color: $text-color;
                }
            }
            &:last-child a {
                pointer-events: none;
                text-decoration: none;
                color: #000;
                &:after {
                    display: none;
                }
            }
        }
    }
}
