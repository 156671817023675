.c-discover {
    position: relative;
}
.c-discover__visual {
    position: relative;
    display: none;
    overflow: hidden;
    @media (min-width: 768px) {
        display: block;
        img {
            max-width: none;
            margin-left: -7.8em;
        }
    }
    @media (min-width: 1024px) {
        img {
            margin-left: 0;
        }
    }
}
.c-discover__accordion {
    @media (min-width: 768px) {
        position: absolute;
        top: 2em;
        left: 60%;
        width: 40%;
    }
}
.c-discover__item__trigger {
    background-color: #8fbdc9;
    color: #fff;
    cursor: pointer;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 4px 0 0 4px;
    padding: 8px 15px;
    font-weight: bold;
    margin: 0.25em 0;
    transition: 0.2s;
    &.is-active {
        background-color: $orange;
    }
}
.c-discover__item__content {
    color: #2c3d4e;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 24px;
    padding: 15px;
    display: none;
    @media (min-width: 1024px) {
        font-size: 16px;
        line-height: 27px;
    }
}
.is-active {
    .c-discover__item__content {
        display: block;
    }
}
// Visuals
.c-discover__trigger {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        opacity: 0.5;
    }
    &.is-active {
        opacity: 1;
    }
}
#kaplink {
    top: 235px;
    left: 274px;
    height: 113px;
    width: 143px;
    background-image: url('/assets/images/module/kapbg.jpg');
}
#luizolderlink {
    top: 346px;
    left: 269px;
    height: 77px;
    width: 148px;
    background-image: url('/assets/images/module/luizolderbg.jpg');
}
#steenzolderlink {
    top: 421px;
    left: 254px;
    height: 73px;
    width: 170px;
    background-image: url('/assets/images/module/steenzolderbg.jpg');
}
#maalzolderlink {
    top: 493px;
    left: 247px;
    height: 53px;
    width: 182px;
    background-image: url('/assets/images/module/maalzolderbg.jpg');
}
#expositielink {
    top: 547px;
    left: 211px;
    height: 206px;
    width: 242px;
    background-image: url('/assets/images/module/expositiebg.jpg');
}
#woonruimtelink {
    top: 754px;
    left: 192px;
    height: 106px;
    width: 276px;
    background-image: url('/assets/images/module/woonruimtebg.jpg');
}
