.c-footer{

}
.c-footer__cta {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 12;
    display: flex;
    justify-content: center;
    a {
        display: flex;;
        border-radius: 4px 4px 0 0;
        background-color: $orange;
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;
        padding: 0.5em 1em calc(0.5em + 8px);
        align-self: flex-start;
        align-items: center;
        &:before {
            content:'';
            background-image: url('/assets/images/ic-organisatie.png');
            display: inline-block;
            width: 36px;
            height: 14px;
            margin-right: 0.35em;
            background-position: left center;
            background-repeat: no-repeat;
        }
        &:after {
            content:'';
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            border-bottom: 8px solid $orange;
        }
        &:hover, &:focus, &:active {
            background-color: lighten($orange, 5%);
            text-decoration: none;
            &:after {
                border-color: lighten($orange, 5%);
            }
        }
    }
}
