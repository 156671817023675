/*
	Objects are immutable once they are set.
	Please extend them with modifiers,
	or use a component class to make exceptions.
*/

.o-item {
	display: inline-block;
	margin: 0;
	vertical-align: top;
}
.o-page-wrap {
	margin: 0;
	padding: 0;
}
.o-section-main {
	margin-bottom: $paragraph-margin*4;
	margin-top: $paragraph-margin*4;
}
.o-section {
	margin-bottom: $paragraph-margin*2;
	margin-top: $paragraph-margin*2;
}
.o-section-sub {
	margin-bottom: $paragraph-margin;
	margin-top: $paragraph-margin;
}
.o-section-subsub {
	margin-bottom: $paragraph-margin/2;
	margin-top: $paragraph-margin/2;
}
.o-section-start {
	margin-top: 0;
}
.o-section-end {
	margin-bottom: 0;
}
.o-mod {
	margin-left: -$paragraph-margin;
	margin-right: -$paragraph-margin;
}
.o-page {
	padding-left: $paragraph-margin;
	padding-right: $paragraph-margin;
}

.o-container {
	max-width: 1045px;
	padding: 0 20px;
	margin: 0 auto;
    @media(min-width: 800px) {
		padding: 0 27px;
	}
}

@media only screen and (min-width: $nav-change) {
	.o-page-wrap {
		display: flex;
		flex-direction: column;
	}
	main {
		order: 2;
	}
}
