/*structure*/

html {
	line-height: 1.5;
	font-size: 100%;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	scroll-behavior: smooth;
}
body {
	font-family: 'Lato', Arial, sans-serif;
	color: $text-color;
	margin: 0;
	padding: 60px 1.5rem $paragraph-margin*2;
	@media(min-width: 800px) {
		padding: 75px 1.5rem $paragraph-margin*2;
	}
}
*, :after, :before {
	margin: 0;
	box-sizing: border-box;
}
p, ol, ul, blockquote, img, table, form, fieldset, pre, iframe, object, embed, video, audio, figure, figcaption, canvas, .par, h4 { margin-top: $paragraph-margin; }
p, ol, ul, blockquote, img, table, form, fieldset, pre, iframe, object, embed, video, audio, figure, figcaption, canvas, .par { margin-bottom: $paragraph-margin; }

li+li {
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
}
:first-child,
h3+p {
	margin-top: 0;
}
:last-child {
	margin-bottom: 0;
}
section+section {
	margin-bottom: $paragraph-margin*4;
	margin-top: $paragraph-margin*4;
}
article+article,
figure+figure {
	margin-bottom: $paragraph-margin*2;
	margin-top: $paragraph-margin*2;
}
img, iframe {
	width: auto;
	height: auto;
	max-width: 100%;
}

/*user input*/
h1, h2, h3, h4, strong, b {
	font-weight: 700;
}
h1,
.title {
	font-size: 28px;
	line-height: 1;
	margin-top: 1em;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	@media(min-width: 800px) {
		font-size: 44px;
	}
}
h2,
.title-sub {
	font-size: 24px;
	line-height: 1.1;
	margin-top: 3rem;
	@media(min-width: 800px) {
		font-size: 34px;
	}
}
h3 {
	margin-bottom: 0.75rem;
}
h3,
h4,
.title-subsub {
	font-size: 16px;
	line-height: inherit;
	@media(min-width: 800px) {
		font-size: 20px;
	}
}
ul {
	padding-left: 1em;
}
q {
	quotes: "“" "”" "‘" "’";
}
blockquote {
	font-size: 2rem;
	line-height: 1.2;
	margin-bottom: $paragraph-margin*2;
	margin-top: $paragraph-margin*2;
	max-width: 34rem;
}
blockquote:before,
q:before,
blockquote::before,
q::before {
	content: open-quote;
}
blockquote:after,
q:after,
blockquote::after,
q::after {
	content: close-quote;
}
a,
a:before a:after,
button,
button:before,
button:after,
datalist,
input,
select,
textarea,
label,
.trans {
	-webkit-tap-highlight-color: transparent;
	transition: 0.1s;
}
a,
:link,
a:visited {
	color: $orange;
	text-decoration: none;
}
a:hover,
a:focus,
a:active {
	text-decoration: underline;
}
a:active,
button:active,
input:active+label,
input+label:active {}
a[href^="tel:"] {
	text-decoration: none;
}
